import './SliderC.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { SliderCContentSection } from '../contentTypes';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';
export interface SliderCProps extends SliderCContentSection {}

export const SliderC = ({ itemList }: SliderCProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div className="slider-c">
      <div className="outer-container">
        <Container>
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ xxl: 10 }}>
              <b2x.EqualHeight>
                <Slider
                  navigation
                  responsive={{
                    md: { slidesPerView: 3.25 },
                    sm: { slidesPerView: 2.25 },
                    xl: { slidesPerView: 3 },
                  }}
                  sliderNextEl={sliderNextButton}
                  sliderPrevEl={sliderPrevButton}
                  slidesPerView={1.1}
                  spaceBetween={16}
                  watchSlidesProgress
                >
                  {itemList?.map((item) => (
                    <SwiperSlide key={item.contentSectionId}>
                      {(slideData) => (
                        <div className="card">
                          <div className="position-relative">
                            {item.img && <b2x.ImageFromContentV1 className="card-img-top" {...item.img} fluid />}
                            {item.cta && <b2x.DeprecatedCta className="stretched-link" cta={item.cta} />}
                          </div>
                          <div className="card-body">
                            <b2x.EqualHeightElement name="item-copy">
                              {item.title && <h3 className="card-title fw-semi-bold">{item.title}</h3>}
                              {item.content && <p className="card-text mb-3">{b2x.formatHtml(item.content)}</p>}
                            </b2x.EqualHeightElement>
                            {item.cta && (
                              <div className="text-end">
                                <b2x.DeprecatedCta className="btn btn-outline-primary fw-semi-bold px-4" cta={item.cta}>
                                  {item.cta.label}
                                </b2x.DeprecatedCta>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
                </Slider>
              </b2x.EqualHeight>
            </b2x.Col>
          </b2x.Row>
        </Container>
        <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
        <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
      </div>
    </div>
  );
};
