import './Accordion.scss';

import classnames from 'classnames';
import React, { useState } from 'react';

import { AccordionContext, useAccordionContext } from './AccordionContext';
import { useWindowSize } from './useWindowSize';
import { formatHtml, getBreakpointValue, renderUI } from './util';

export interface AccordionProps {
  accordionColClassName?: string;
  alwaysOpened?: boolean;
  children?: React.ReactNode;
  className?: string;
  columnLayoutFrom?: 'md' | 'lg' | 'xl' | 'xxl';
  id: string;
  itemBodyClassName?: string;
  itemButtonClassName?: string;
  itemClassName?: string;
  itemHeaderClassName?: string;
  withoutXPadding?: boolean;
}

export const Accordion = ({
  accordionColClassName,
  alwaysOpened = false,
  children,
  className,
  columnLayoutFrom,
  id,
  itemBodyClassName,
  itemButtonClassName,
  itemClassName,
  itemHeaderClassName,
  withoutXPadding,
}: AccordionProps) => {
  const [horizontal, setHorizontal] = useState(false);
  const windowSize = useWindowSize();

  React.useEffect(() => {
    if (columnLayoutFrom && windowSize.width >= getBreakpointValue(columnLayoutFrom)) {
      setHorizontal(true);
    } else {
      setHorizontal(false);
    }
  }, [columnLayoutFrom, windowSize.width]);

  return renderUI({
    bs5: (
      <div className={classnames('accordion', 'Accordion', className, { 'horizontal-layout': horizontal })} id={id}>
        <AccordionContext
          accordionColClassName={accordionColClassName}
          alwaysOpened={alwaysOpened}
          columnLayoutFrom={columnLayoutFrom}
          id={id}
          itemBodyClassName={itemBodyClassName}
          itemButtonClassName={itemButtonClassName}
          itemClassName={itemClassName}
          itemHeaderClassName={itemHeaderClassName}
          withoutXPadding={withoutXPadding}
        >
          <div className="row">{children}</div>
        </AccordionContext>
      </div>
    ),
  });
};

export interface AccordionItemProps {
  accordionBodyClassName?: string;
  accordionButtonClassName?: string;
  children?: React.ReactNode;
  className?: string;
  id: string;
  show?: boolean;
  title: string | React.ReactElement;
}

export const AccordionItem = ({
  accordionBodyClassName,
  accordionButtonClassName,
  children,
  className,
  id,
  show = false,
  title,
}: AccordionItemProps) => {
  const accordionContext = useAccordionContext();
  return renderUI({
    bs5: (
      <div
        className={classnames(
          accordionContext.columnLayoutFrom ? `col-${accordionContext.columnLayoutFrom}` : 'col-12',
          accordionContext.accordionColClassName,
          className
        )}
      >
        <div className={classnames('accordion-item', accordionContext.itemClassName)}>
          <h3 className={classnames('accordion-header', accordionContext.itemHeaderClassName)} id={`heading-${id}`}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <button
              aria-controls={`collapse-${id}`}
              aria-expanded={show}
              className={classnames(
                'accordion-button',
                { collapsed: !show },
                accordionContext.itemButtonClassName,
                {
                  'px-0': accordionContext.withoutXPadding,
                },
                accordionButtonClassName
              )}
              data-bs-target={`#collapse-${id}`}
              data-bs-toggle="collapse"
              type="button"
            >
              {typeof title === 'string' ? formatHtml(title) : title}
            </button>
          </h3>
          <div
            aria-labelledby={`heading-${id}`}
            className={classnames('accordion-collapse collapse', { show: show })}
            data-bs-parent={!accordionContext.alwaysOpened ? `#${accordionContext.id}` : undefined}
            id={`collapse-${id}`}
          >
            <div
              className={classnames(
                'accordion-body',
                accordionContext.itemBodyClassName,
                {
                  'px-0': accordionContext.withoutXPadding,
                },
                accordionBodyClassName
              )}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
