/* eslint-disable react/forbid-elements */
import './SliderA.scss';

import { b2x } from '@b2x/react/src';
import { auto } from '@popperjs/core';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { SliderAContentSection } from '../contentTypes';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';
export interface SliderAProps extends SliderAContentSection {}

export const SliderA = ({ sliderList }: SliderAProps) => {
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const { width } = b2x.useWindowSize();
  const [slideMediaHeight, setSlideMediaHeight] = React.useState<number>();

  const fixNavigationPosition = React.useCallback(() => {
    const firstSlideMediaHeight = sliderRef.current?.querySelector('.slide-media-container')?.clientHeight;
    firstSlideMediaHeight && setSlideMediaHeight(firstSlideMediaHeight / 2);
  }, []);

  React.useEffect(() => {
    fixNavigationPosition();
  }, [fixNavigationPosition, width]);

  return (
    <div className="slider-a" ref={sliderRef}>
      <Slider
        autoplay={{ delay: 3400 }}
        loop
        navigation
        sliderNextEl={sliderNextButton}
        sliderPrevEl={sliderPrevButton}
        slidesPerView={1}
        spaceBetween={0}
        speed={500}
        watchSlidesProgress
      >
        {sliderList?.map((slide) => (
          <SwiperSlide key={slide.contentSectionId}>
            {(slideData) => (
              <>
                <div className="slide-media-container position-relative text-center">
                  {slide.video?.src?.xs?.video ? (
                    <b2x.DeprecatedVideoFromContent autoplay loop {...slide.video} onLoad={fixNavigationPosition} />
                  ) : (
                    slide.img && (
                      <b2x.ImageFromContentV1 {...slide.img} className="w-100" fluid onLoad={fixNavigationPosition} />
                    )
                  )}
                  {slide.copy?.cta && <b2x.DeprecatedCta className="stretched-link" cta={slide.copy.cta} />}
                </div>
                <div className="slide-text-container d-flex align-items-center">
                  <Container>
                    <div
                      className={classnames(
                        'slide-text-wrapper d-flex justify-content-center text-center',
                        { 'justify-content-lg-start text-lg-start': slide.copy?.alignment === 'left' },
                        { 'justify-content-lg-end text-lg-end': slide.copy?.alignment === 'right' },
                        { 'justify-content-lg-center text-lg-center': slide.copy?.alignment === 'center' }
                      )}
                    >
                      <div
                        className={classnames(
                          'slide-text-content py-4',
                          `text-${slide.copy?.variant}`,
                          { 'ps-lg-5': slide.copy?.alignment === 'left' },
                          { 'pe-lg-5': slide.copy?.alignment === 'right' }
                        )}
                      >
                        {slide.copy?.title && (
                          <h3 className="h1 fw-semi-bold mb-2">{b2x.formatHtml(slide.copy.title)}</h3>
                        )}
                        {slide.copy?.content && (
                          <p className="h3 fw-semi-bold mb-4">{b2x.formatHtml(slide.copy.content)}</p>
                        )}
                        {slide.copy?.cta && slide.copy.cta.label && (
                          <b2x.DeprecatedCta cta={slide.copy.cta}>
                            <div className={`btn btn-${slide.copy.variant} fw-semi-bold px-4`}>
                              {slide.copy.cta.label}
                            </div>
                          </b2x.DeprecatedCta>
                        )}
                      </div>
                    </div>
                  </Container>
                </div>
              </>
            )}
          </SwiperSlide>
        ))}
      </Slider>
      <div className="outer-container">
        <SliderButton
          direction="left"
          innerRef={sliderPrevButton}
          size="large"
          style={{ bottom: auto, top: slideMediaHeight }}
        />
        <SliderButton
          direction="right"
          innerRef={sliderNextButton}
          size="large"
          style={{ bottom: auto, top: slideMediaHeight }}
        />
      </div>
    </div>
  );
};
