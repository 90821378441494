import './PageHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from './Container';
import { PageHeaderContentSection } from './contentTypes';

export interface PageHeaderProps extends PageHeaderContentSection {
  className?: string;
  showMoreContent?: boolean;
}

export const PageHeader = ({ className, copy, media, showMoreContent }: PageHeaderProps) => {
  return (
    <div className={classnames('page-header', className)}>
      {(media?.video?.src?.xs?.video || media?.img) && (
        <div className="page-header-media-container text-center">
          {media.video?.src?.xs?.video ? (
            <b2x.DeprecatedVideoFromContent autoplay loop {...media.video} />
          ) : (
            media.img && <b2x.ImageFromContentV1 {...media.img} className="w-100" fluid />
          )}
        </div>
      )}
      {(copy?.title || copy?.content) && (
        <div className="page-header-copy-container d-flex align-items-center">
          <Container>
            <div
              className="d-block d-lg-flex"
              style={{
                justifyContent:
                  copy.alignment === 'left'
                    ? 'flex-start'
                    : copy.alignment === 'right'
                    ? 'flex-end'
                    : copy.alignment === 'center'
                    ? 'center'
                    : undefined,
                textAlign:
                  copy.alignment === 'left'
                    ? 'left'
                    : copy.alignment === 'right'
                    ? 'right'
                    : copy.alignment === 'center'
                    ? 'center'
                    : undefined,
              }}
            >
              <div className={classnames('page-header-copy', 'py-3', `text-${copy.variant}`)}>
                {copy.title && <h1 className="fw-semi-bold">{b2x.formatHtml(copy.title)}</h1>}
                {copy.content &&
                  (showMoreContent ? (
                    <b2x.ShowMore activeOnBreakpoint={{ xs: true }} content={copy.content} truncateTo={20} />
                  ) : (
                    <p className="h5">{b2x.formatHtml(copy.content)}</p>
                  ))}
              </div>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};
