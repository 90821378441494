import './ArticlePage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { ArticlePageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { PageHeader } from '../PageHeader';
import { ProductTile } from '../ProductTile';
import { Section } from '../Section';
import { SliderB } from '../slider/SliderB';
import { SocialSharing } from '../SocialSharing';
import { Page } from './Page';

export interface ArticlePageProps {}

export const ArticlePage = (props: ArticlePageProps) => {
  /* const page = b2x.usePage<ArticlePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  }); */

  const page = b2x.useContentByPath<ArticlePageContentType>({ populate: { pages: true } });

  return (
    <Page className="article-page border-bottom" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.body.imagePreview && <PageHeader className={'mb-lg-5 mb-3'} media={{ img: page.body.imagePreview }} />}

      <Container className="mt-lg-5 mt-3">
        <Breadcrumb contentCodeListToHide={['ART_BLOG_CATEGORY']} lastItemLinkable />
        <b2x.Row className="mb-3 mb-lg-5">
          <b2x.Col className="text-end mb-2 mt-3 order-md-last align-self-end" size={{ md: 2, xs: 12 }}>
            <div className="extra-small mb-2">{t('misc.article.share')}</div>
            <SocialSharing />
          </b2x.Col>
          <b2x.Col size={{ md: 10, xs: 12 }}>
            {page?.body.title && <h1 className="fw-bold mt-3">{b2x.formatHtml(page.body.title)}</h1>}
          </b2x.Col>
        </b2x.Row>
      </Container>

      {page?.body.showNewTemplate ? (
        page.body.sectionList?.map((section) => (
          <Section className={classnames(`bg-${section.background}`, 'py-3 py-lg-5')} key={section.contentSectionId}>
            <Container>
              {section.rowList?.map((row) => (
                <div
                  className={classnames(
                    !row.disablePaddingBottom && !row.disablePaddingTop
                      ? 'py-3 py-lg-5'
                      : row.disablePaddingTop
                      ? 'pb-5'
                      : row.disablePaddingBottom && 'pt-5'
                  )}
                  key={row.contentSectionId}
                >
                  <b2x.Row className="justify-content-center">
                    {(row.copy?.title || row.copy?.content || row.copy?.cta?.label) && (
                      <b2x.Col
                        className={classnames(
                          'order-2',
                          row.copy.alignment === 'left'
                            ? 'text-start'
                            : row.copy.alignment === 'right'
                            ? 'text-lg-end'
                            : row.copy.alignment === 'center' && 'text-center',
                          'd-flex align-items-center'
                        )}
                        size={{ lg: row.copy.columnSize as b2x.ColSize, xs: 12 }}
                      >
                        <div
                          className={classnames(
                            'w-100',
                            { 'pe-0 pe-lg-5': row.copy.alignment === 'left' && !row.beforeMediaContent },
                            { 'ps-0 ps-lg-5': row.beforeMediaContent }
                          )}
                        >
                          {row.copy.title && <h3 className="h2 fw-semi-bold">{b2x.formatHtml(row.copy.title)}</h3>}
                          {row.copy.content && <div>{b2x.formatHtml(row.copy.content)}</div>}
                          {row.copy.cta?.label && (
                            <div className="mt-3 mb-2">
                              <b2x.DeprecatedCta
                                className={classnames(
                                  'btn',
                                  row.copy.cta.variant ? `btn-${row.copy.cta.variant}` : 'btn-primary'
                                )}
                                cta={row.copy.cta}
                              >
                                {row.copy.cta.label}
                              </b2x.DeprecatedCta>
                            </div>
                          )}
                        </div>
                      </b2x.Col>
                    )}
                    {(row.media?.video?.src?.xs?.video || row.media?.img?.src?.xs || row.media?.productId) && (
                      <b2x.Col
                        className={classnames(
                          'd-flex align-items-center order-1',
                          row.beforeMediaContent ? 'order-lg-1' : 'order-lg-3'
                        )}
                        size={{ lg: row.media.columnSize as b2x.ColSize, xs: 12 }}
                      >
                        <div className="mb-3 mb-lg-0">
                          {row.media.productId ? (
                            <b2x.ProductById id={row.media.productId}>
                              {(product) =>
                                product !== undefined && (
                                  <b2x.EqualHeight>
                                    <b2x.Listing name="Article Page section" products={[product]}>
                                      <ProductTile product={product} />
                                    </b2x.Listing>
                                  </b2x.EqualHeight>
                                )
                              }
                            </b2x.ProductById>
                          ) : row.media.video?.src?.xs?.video ? (
                            <b2x.DeprecatedVideoFromContent controls {...row.media.video} />
                          ) : (
                            row.media.img && <b2x.ImageFromContentV1 {...row.media.img} className="rounded" fluid />
                          )}
                        </div>
                      </b2x.Col>
                    )}
                  </b2x.Row>
                </div>
              ))}
            </Container>
          </Section>
        ))
      ) : (
        <Container>
          {page?.body.content && (
            <b2x.Row>
              <b2x.Col size={{ xs: 12 }}>
                <div className="article-content my-3 my-lg-5">{b2x.formatHtml(page.body.content)}</div>
              </b2x.Col>
            </b2x.Row>
          )}
        </Container>
      )}

      {page?.body.productsSliderList && page.body.productsSliderList.length > 0 && (
        <Section className="mb-3 mb-lg-5">
          <Container>
            <h3 className="h2 fw-semi-bold text-center mb-5">{b2x.formatHtml(page.body.productsSliderTitle)}</h3>
          </Container>
          <b2x.ProductsByIds ids={page.body.productsSliderList.map(({ productId }) => productId)}>
            {(products) =>
              products !== undefined && (
                <b2x.EqualHeight>
                  <b2x.Listing name="Article page - Related products" products={products}>
                    <SliderB products={products} />
                  </b2x.Listing>
                </b2x.EqualHeight>
              )
            }
          </b2x.ProductsByIds>
        </Section>
      )}

      {page?.pages && page.pages.length > 0 && (
        <Container>
          <div className="border-top pt-3 pt-lg-4 pb-lg-5 mt-3">
            <div className="categories d-flex flex-nowrap pb-3">
              {page.pages.map((cat) => (
                <b2x.router.Link
                  className="extra-small Button btn btn-outline-secondary me-2"
                  key={cat.id}
                  to={cat.fullPath}
                >
                  {b2x.formatHtml(cat.name)}
                </b2x.router.Link>
              ))}
            </div>
          </div>
        </Container>
      )}
    </Page>
  );
};
