import './GiftCardPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { GiftCardContentType } from '../contentTypes';
import { Page } from './Page';

export interface GiftCardPageProps {}

export const GiftCardPage = (props: GiftCardPageProps) => {
  const product = b2x.useProduct({ populate: { priceRange: true, skus: { attributes: true, price: true } } });
  const content = b2x.useContent<GiftCardContentType>('GIFT_CARD_CONTENT');

  return (
    <Page className="gift-card-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {content?.body.img && <b2x.ImageFromContentV1 {...content.body.img} className="w-100" fluid />}
      <Container className="pt-5">
        {product && (
          <b2x.AddToCartFormHelper
            className="mb-3 mb-md-0 pb-3 pb-md-0"
            imageSkuVariantAttributeTypeCode="Gift_card_design"
            product={product}
            scope="product"
          >
            {({ fieldsHelper, image }) => (
              <b2x.Row>
                <b2x.Col size={{ md: 6, xs: 12 }}>
                  <div className="img-container">
                    <b2x.Image {...image} className="mb-4 mb-md-5" fluid />
                  </div>
                  <div className="mb-5">
                    {content?.body.stepList?.map(({ contentSectionId, description }, index) => (
                      <h5 className="mb-3 fw-light d-flex gap-3 align-items-center" key={contentSectionId}>
                        <div
                          className="bg-secondary rounded-circle h-3 d-flex justify-content-center align-items-center"
                          style={{ height: 30, width: 30 }}
                        >
                          <span className="fw-semi-bold">{index + 1}</span>
                        </div>
                        {b2x.formatHtml(description)}
                      </h5>
                    ))}
                  </div>
                </b2x.Col>
                <b2x.Col size={{ md: 6, xs: 12 }}>
                  {Object.entries(fieldsHelper.skuAttributes).map(([name, { attribute, formGroup, select }]) => (
                    <div key={name}>
                      {attribute.typeCode === 'Gift_card_valore' ? (
                        <>
                          <h5 className="border-bottom fw-semi-bold mb-3 pb-3">1. {content?.body.formLabels?.value}</h5>
                          <b2x.FormGroup {...formGroup}>
                            <b2x.Select {...select} />
                          </b2x.FormGroup>
                        </>
                      ) : (
                        <></>
                        // <div className="design-container">
                        //   <h5 className="border-bottom fw-semi-bold mb-3 py-3">
                        //     2. {content?.body.formLabels?.design}
                        //   </h5>
                        //   <b2x.FormGroup {...formGroup} label={undefined}>
                        //     {radios.map(({ radio, skus }) => (
                        //       <b2x.Radio key={radio.id} {...radio} className="me-3 mb-2">
                        //         <CustomRadio imageUrl={skus[0]?.image?.src} isSquare />
                        //       </b2x.Radio>
                        //     ))}
                        //   </b2x.FormGroup>
                        // </div>
                      )}
                    </div>
                  ))}
                  {fieldsHelper.giftCard && (
                    <div>
                      <h5 className="border-bottom fw-semi-bold mb-3 py-3">2. {content?.body.formLabels?.message}</h5>
                      <b2x.FormGroup {...fieldsHelper.giftCard.senderName.formGroup}>
                        <b2x.TextInput {...fieldsHelper.giftCard.senderName.textInput} />
                      </b2x.FormGroup>
                      <b2x.FormGroup {...fieldsHelper.giftCard.receiverName.formGroup}>
                        <b2x.TextInput {...fieldsHelper.giftCard.receiverName.textInput} />
                      </b2x.FormGroup>
                      <b2x.FormGroup {...fieldsHelper.giftCard.receiverEmail.formGroup}>
                        <b2x.TextInput {...fieldsHelper.giftCard.receiverEmail.textInput} />
                      </b2x.FormGroup>
                      <b2x.FormGroup {...fieldsHelper.giftCard.message.formGroup}>
                        <b2x.TextArea {...fieldsHelper.giftCard.message.textArea} />
                      </b2x.FormGroup>
                    </div>
                  )}
                  <div className="d-grid">
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </div>
                </b2x.Col>
              </b2x.Row>
            )}
          </b2x.AddToCartFormHelper>
        )}
        {!!content?.body.howItWorks?.faqList?.length && (
          <div className="pt-4 pt-md-5 pb-3 pb-md-5 px-3 px-md-5 bg-secondary">
            <h5 className="text-uppercase text-center fw-semi-bold mb-4 mb-md-5">{content.body.howItWorks.title}</h5>
            <b2x.Row cols={{ md: 2, xs: 1 }} gap={3}>
              {content.body.howItWorks.faqList.map(({ contentSectionId, description, title }) => (
                <b2x.Col key={contentSectionId}>
                  <div className="h-100 bg-white p-3 p-md-4">
                    <h6 className="fw-semi-bold">{title}</h6>
                    <small className="fw-light lh-1">{b2x.formatHtml(description)}</small>
                  </div>
                </b2x.Col>
              ))}
            </b2x.Row>
          </div>
        )}
      </Container>
    </Page>
  );
};
