import { Col } from '../Col';
import { ImageContentSectionV1 } from '../contentTypes';
import { ReviewForm, ReviewFormModalProps } from '../form/ReviewForm';
import { t } from '../i18n/i18n';
import { ImageFromContentV1 } from '../ImageFromContentV1';
import { Row } from '../Row';

export interface ReviewFormModalAProps extends ReviewFormModalProps {
  image?: ImageContentSectionV1;
}

export const ReviewFormModalA = ({ image, ...otherProps }: ReviewFormModalAProps) => {
  return (
    <Row>
      {image && (
        <Col size={4}>
          <ImageFromContentV1 className="review-modal-image" fluid {...image} />
        </Col>
      )}
      <Col size={image ? 8 : 12}>
        <h3>{t('form.reviewForm.modal.title')}</h3>
        <ReviewForm {...otherProps} />
      </Col>
    </Row>
  );
};
