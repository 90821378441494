import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType, MiscellaneousContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import logoTransparent from './images/logo-transparent.svg';

const fadeDuration = 200;

export interface DesktopHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  recalculateHeaderHeight(): void;
  secondRowRef: React.RefObject<HTMLDivElement>;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({ content, recalculateHeaderHeight, secondRowRef, visibleFrom }: DesktopHeaderProps) => {
  const { session, shippingCountry } = b2x.useAppContext();
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();
  const { headerCheckout } = useAppContext();

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER_DESKTOP: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible(false);
    setSearchBoxVisible((prevState) => !prevState);
  }, []);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return !headerCheckout ? (
    <>
      <FirstRow
        activeCategory={activeCategory}
        localeBoxVisible={localeBoxVisible}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
      <SecondRow
        activeCategory={activeCategory}
        innerRef={secondRowRef}
        localeBoxVisible={localeBoxVisible}
        menu={menu}
        recalculateHeaderHeight={recalculateHeaderHeight}
        searchBoxVisible={searchBoxVisible}
        setActiveCategory={setActiveCategory}
        toggleLocaleBoxVisible={toggleLocaleBoxVisible}
        toggleSearchBoxVisible={toggleSearchBoxVisible}
        visibleFrom={visibleFrom}
      />
    </>
  ) : (
    <div
      className={classnames(
        'HeaderCheckoutDesktop sticky-top',
        `d-none d-${visibleFrom}-block`,
        'py-3 shadow-sm bg-white'
      )}
    >
      <div className="container-xxl">
        <div className="d-flex justify-content-between align-items-center">
          <div className="logo" style={{ flex: '1 1 0' }}>
            <Logo
              activeCategory={activeCategory}
              localeBoxVisible={localeBoxVisible}
              recalculateHeaderHeight={recalculateHeaderHeight}
              searchBoxVisible={searchBoxVisible}
            />
          </div>
          <div className="text-center h5 m-0" style={{ flex: '1 1 0' }}>
            {session?.cart?.itemsNumber && (
              <b2x.Fetching item={session.cart.itemsNumber}>
                {(count) => t('header.cartSummary', { count: count })}
              </b2x.Fetching>
            )}
          </div>
          <div className="text-end extra-small fw-light text-dark text-uppercase" style={{ flex: '1 1 0' }}>
            {session?.cart?.shippingProfile?.freeThreshold &&
              t('header.freeShippingFrom', {
                from: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
                locale: shippingCountry?.name,
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

interface FirstRowProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const FirstRow = ({
  activeCategory,
  localeBoxVisible,
  recalculateHeaderHeight,
  searchBoxVisible,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: FirstRowProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();
  const { setHeaderFirstRowRef } = useAppStaticContext();
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <div
      className={classnames('header-first-row', `d-none d-${visibleFrom}-block`, {
        transparent: transparentHeader && activeCategory === undefined && !localeBoxVisible && !searchBoxVisible,
      })}
      ref={setHeaderFirstRowRef}
    >
      <Container>
        <b2x.Row className="align-items-center pt-3">
          <b2x.Col className="d-flex">
            <LocaleToggle
              activeCategory={activeCategory}
              localeBoxVisible={localeBoxVisible}
              searchBoxVisible={searchBoxVisible}
              toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            />
            <b2x.router.Link
              className={classnames('ps-2 text-decoration-none small fw-bold', {
                'text-white':
                  transparentHeader &&
                  isHeaderFirstRowVisible &&
                  activeCategory === undefined &&
                  !localeBoxVisible &&
                  !searchBoxVisible,
              })}
              to={getPagePath('SITE_STORE_LOCATOR')}
            >
              <Icon name="store-locator" size={25} />
              <span className="ps-1">Store locator</span>
            </b2x.router.Link>
          </b2x.Col>
          <b2x.Col className="d-flex flex-grow-0">
            <Logo
              activeCategory={activeCategory}
              localeBoxVisible={localeBoxVisible}
              recalculateHeaderHeight={recalculateHeaderHeight}
              searchBoxVisible={searchBoxVisible}
            />
          </b2x.Col>
          <b2x.Col className="d-flex justify-content-end">
            <b2x.Fade delayIn={fadeDuration / 2} duration={fadeDuration} show={isHeaderFirstRowVisible}>
              <Toggles
                activeCategory={activeCategory}
                localeBoxVisible={localeBoxVisible}
                searchBoxVisible={searchBoxVisible}
                toggleSearchBoxVisible={toggleSearchBoxVisible}
              />
            </b2x.Fade>
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};

interface SecondRowProps {
  activeCategory?: b2x.MenuApiDto;
  innerRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  toggleSearchBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

const SecondRow = ({
  activeCategory,
  innerRef,
  localeBoxVisible,
  menu,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  toggleLocaleBoxVisible,
  toggleSearchBoxVisible,
  visibleFrom,
}: SecondRowProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('header-second-row-wrapper sticky-top', `d-none d-${visibleFrom}-block`, {
        sticky: !isHeaderFirstRowVisible,
      })}
      ref={innerRef}
    >
      <div
        className={classnames(
          'header-second-row',
          { 'shadow-sm': !searchBoxVisible && !localeBoxVisible },
          {
            transparent:
              transparentHeader &&
              isHeaderFirstRowVisible &&
              activeCategory === undefined &&
              !localeBoxVisible &&
              !searchBoxVisible,
          }
        )}
      >
        <Container>
          <b2x.Row className="position-relative align-items-stretch">
            <b2x.Col className="d-flex py-1">
              <b2x.Fade duration={fadeDuration} show={!isHeaderFirstRowVisible}>
                <Logo
                  activeCategory={activeCategory}
                  localeBoxVisible={localeBoxVisible}
                  recalculateHeaderHeight={recalculateHeaderHeight}
                  searchBoxVisible={searchBoxVisible}
                />
              </b2x.Fade>
            </b2x.Col>
            <b2x.Col className="d-flex flex-grow-0">
              <Menu
                activeCategory={activeCategory}
                localeBoxVisible={localeBoxVisible}
                menu={menu}
                searchBoxVisible={searchBoxVisible}
                setActiveCategory={setActiveCategory}
                subMenuDropdownRef={subMenuDropdownRef}
              />
            </b2x.Col>
            <b2x.Col className="d-flex justify-content-end m-auto" style={{ height: 'fit-content' }}>
              <b2x.Fade duration={fadeDuration} show={!isHeaderFirstRowVisible}>
                <Toggles
                  activeCategory={activeCategory}
                  localeBoxVisible={localeBoxVisible}
                  searchBoxVisible={searchBoxVisible}
                  toggleSearchBoxVisible={toggleSearchBoxVisible}
                />
              </b2x.Fade>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface LocaleToggleProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  searchBoxVisible: boolean;
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({
  activeCategory,
  localeBoxVisible,
  searchBoxVisible,
  toggleLocaleBoxVisible,
}: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();

  return (
    <Button
      alignItems="center"
      className={classnames('text-decoration-none', {
        'text-white':
          transparentHeader &&
          isHeaderFirstRowVisible &&
          activeCategory === undefined &&
          !localeBoxVisible &&
          !searchBoxVisible,
      })}
      iconStart={{ name: 'translate', size: 25 }}
      label={
        locale &&
        shippingCountry && (
          <span>
            {shippingCountry.code} | {locale.languageCode}
          </span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      variant="blank"
    />
  );
};

interface TogglesProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  searchBoxVisible: boolean;
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ activeCategory, localeBoxVisible, searchBoxVisible, toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      <div className="hstack gap-2">
        <Button
          className={classnames({
            'text-white':
              transparentHeader &&
              isHeaderFirstRowVisible &&
              activeCategory === undefined &&
              !localeBoxVisible &&
              !searchBoxVisible,
          })}
          iconEnd={{ name: 'search', size: 25 }}
          onClick={toggleSearchBoxVisible}
          variant="blank"
        />
        {session?.customer ? (
          <b2x.router.Link
            className={classnames('lh-1', {
              'text-white':
                transparentHeader &&
                isHeaderFirstRowVisible &&
                activeCategory === undefined &&
                !localeBoxVisible &&
                !searchBoxVisible,
            })}
            to={getPagePath('SITE_ACCOUNT')}
          >
            <Icon name="account-full" size={25} />
          </b2x.router.Link>
        ) : (
          <Button
            className={classnames({
              'text-white':
                transparentHeader &&
                isHeaderFirstRowVisible &&
                activeCategory === undefined &&
                !localeBoxVisible &&
                !searchBoxVisible,
            })}
            iconEnd={{ name: 'account', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}

        {session?.customer ? (
          <b2x.router.Link
            className={classnames('lh-1', {
              'text-white':
                transparentHeader &&
                isHeaderFirstRowVisible &&
                activeCategory === undefined &&
                !localeBoxVisible &&
                !searchBoxVisible,
            })}
            to="/account/area/wishlist"
          >
            <Button
              className={classnames('position-relative', {
                'text-white':
                  transparentHeader &&
                  isHeaderFirstRowVisible &&
                  activeCategory === undefined &&
                  !localeBoxVisible &&
                  !searchBoxVisible,
              })}
              variant="blank"
            >
              <Icon name={'wishlist'} size={25} />
              {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                  {(session.wishlist.products ? session.wishlist.products.length : 0) +
                    (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                </span>
              )}
            </Button>
          </b2x.router.Link>
        ) : (
          <Button
            className={classnames({
              'text-white':
                transparentHeader &&
                isHeaderFirstRowVisible &&
                activeCategory === undefined &&
                !localeBoxVisible &&
                !searchBoxVisible,
            })}
            iconEnd={{ name: 'wishlist', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}

        <Button
          className={classnames('position-relative', {
            'text-white':
              transparentHeader &&
              isHeaderFirstRowVisible &&
              activeCategory === undefined &&
              !localeBoxVisible &&
              !searchBoxVisible,
          })}
          onClick={showCartOffcanvas}
          variant="blank"
        >
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};

interface LogoProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
}

const Logo = ({ activeCategory, localeBoxVisible, recalculateHeaderHeight, searchBoxVisible }: LogoProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();

  return (
    <b2x.router.Link to="/">
      <b2x.Image
        onLoad={recalculateHeaderHeight}
        src={
          transparentHeader &&
          isHeaderFirstRowVisible &&
          activeCategory === undefined &&
          !localeBoxVisible &&
          !searchBoxVisible
            ? logoTransparent
            : logo
        }
      />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item px-2 px-xl-4 d-flex align-items-center', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  className?: string;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  className,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames(
          'text-decoration-none p-0 text-nowrap btn-lg',
          {
            active: activeCategory && activeCategory.id === firstLevelCategory.id,
          },
          className
        )}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  localeBoxVisible: boolean;
  menu?: b2x.MenuApiDto;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({
  activeCategory,
  localeBoxVisible,
  menu,
  searchBoxVisible,
  setActiveCategory,
  subMenuDropdownRef,
}: MenuProps) => {
  const { isHeaderFirstRowVisible, transparentHeader } = useAppContext();

  const menuButtonClassName =
    transparentHeader &&
    isHeaderFirstRowVisible &&
    activeCategory === undefined &&
    !localeBoxVisible &&
    !searchBoxVisible
      ? 'text-white'
      : undefined;

  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              className={menuButtonClassName}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'text-decoration-none p-0 text-nowrap Button btn btn-blank btn-lg d-inline-flex',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  },
                  menuButtonClassName
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div
      className={classnames({ mounted: isMounted }, 'sub-menu-dropdown shadow-sm w-100 py-5')}
      ref={subMenuDropdownRef}
    >
      <Container>
        <b2x.Row className="justify-content-center" cols={'auto'}>
          {/* {activeCategory.id} */}
          {activeCategory.children.map((secondLevelCategory, index) => (
            <b2x.Col
              className={classnames(
                ' flex-column px-3 px-xxl-4',
                activeCategory.children.length - 1 === index && activeCategory.children.length === 6
                  ? 'd-none d-xl-flex'
                  : 'd-flex'
              )}
              key={secondLevelCategory.id}
            >
              {secondLevelCategory.children.map((thirdLevelCategory) => (
                <div key={thirdLevelCategory.id}>
                  {thirdLevelCategory.content &&
                    b2x.typedContent<HeaderMenuItemContentType>(thirdLevelCategory.content, (content) => (
                      <>
                        {content.body.img?.src?.xs && (
                          <div className="mb-4">
                            {content.body.cta?.to?.code ? (
                              <b2x.DeprecatedCta cta={content.body.cta}>
                                <b2x.ImageFromContentV1 fluid {...content.body.img} className="rounded" />
                              </b2x.DeprecatedCta>
                            ) : (
                              <b2x.ImageFromContentV1 fluid {...content.body.img} className="rounded" />
                            )}
                          </div>
                        )}
                        {content.body.cta?.label && (
                          <div className="">
                            <h6 className="small">
                              <b2x.DeprecatedCta
                                className="text-reset fw-bold text-decoration-none"
                                cta={content.body.cta}
                              >
                                {content.body.cta.label}
                              </b2x.DeprecatedCta>
                            </h6>
                          </div>
                        )}
                      </>
                    ))}
                  {thirdLevelCategory.children.length === 0 && thirdLevelCategory.link && (
                    <h6 className="small text-uppercase fw-bold mt-3">
                      <b2x.router.Link className="text-reset" onClick={close} to={thirdLevelCategory.link}>
                        {thirdLevelCategory.label}
                      </b2x.router.Link>
                    </h6>
                  )}

                  {thirdLevelCategory.children.map((fourLevelCategory) => (
                    <div key={fourLevelCategory.id}>
                      {fourLevelCategory.children.length > 0 ? (
                        <ul className="list-unstyled" style={{ maxWidth: '250px' }}>
                          <div className="mb-3">
                            <h6 className="small text-uppercase text-dark fw-bold">
                              <b2x.router.Link
                                className="text-reset text-decoration-none"
                                onClick={close}
                                to={fourLevelCategory.link}
                              >
                                {fourLevelCategory.label}
                              </b2x.router.Link>
                            </h6>
                          </div>
                          {fourLevelCategory.children.map(
                            (fifthLevelCategory) =>
                              fifthLevelCategory.link && (
                                <li key={fifthLevelCategory.id}>
                                  <b2x.router.Link
                                    className="text-reset text-decoration-none"
                                    onClick={close}
                                    to={fifthLevelCategory.link}
                                  >
                                    <span className="small">{fifthLevelCategory.label}</span>
                                  </b2x.router.Link>
                                </li>
                              )
                          )}
                        </ul>
                      ) : (
                        <h6 className="small text-uppercase fw-bold">
                          <b2x.router.Link className="text-reset" onClick={close} to={fourLevelCategory.link}>
                            {fourLevelCategory.label}
                          </b2x.router.Link>
                        </h6>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </b2x.Col>
          ))}
        </b2x.Row>
      </Container>
    </div>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleSearchBoxVisible}>
      <div className="row justify-content-center">
        <div className="col-8">
          <b2x.SimpleSearchForm onSuccess={toggleSearchBoxVisible} />
        </div>
      </div>
    </Box>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
        </div>
        {content?.body.alertChangeCountry && (
          <div className="small pt-3">{b2x.formatHtml(content.body.alertChangeCountry)}</div>
        )}
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5 shadow-sm" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
