import './SearchPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Container } from '../Container';
import { SearchPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { PageHeader } from '../PageHeader';
import { useSearchFiltersOffcanvas } from '../SearchFiltersOffcanvas';
import { Section } from '../Section';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { page, searchResult } = b2x.useSearch<SearchPageContentType>({
    defaultPageSize: 16,
    pageOptions: {
      populate: {
        content: true,
      },
    },
  });

  const searchPageContent = b2x.useContent<SearchPageContentType>('SEARCH_DEFAULT_PAGE_CONTENT');

  const [searchFiltersOffcanvas, showSearchFiltersOffcanvas] = useSearchFiltersOffcanvas({
    placement: 'right',
    searchResult: searchResult,
  });

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="search-page" noPaddingTop>
          {searchFiltersOffcanvas}
          <div className="border-bottom border-gray-200">
            <PageHeader
              showMoreContent
              {...(page?.content?.body.header ? page.content.body.header : searchPageContent?.body.header)}
            />
          </div>
          <Container>
            <b2x.Div paddingY={{ xs: 3 }}>
              <b2x.Row>
                <b2x.Col>
                  <Breadcrumb />
                </b2x.Col>
                <b2x.Col size={'auto'}>
                  <b2x.Div className="extra-small" textAlign={'end'}>
                    {searchResult?.itemsCount && t('misc.searchItemResult', { count: searchResult.itemsCount })}
                  </b2x.Div>
                </b2x.Col>
              </b2x.Row>
            </b2x.Div>
            <b2x.Div display={{ lg: 'none', xs: 'block' }} marginBottom={3}>
              <Button
                alignItems="center"
                className="btn-sm"
                iconEnd={{ name: 'filter', size: 20 }}
                label={t('misc.filterAndOrderBy')}
                onClick={showSearchFiltersOffcanvas}
                type="button"
                variant="outline-primary"
              />
            </b2x.Div>
          </Container>
          <b2x.Div className="search-page-toolbar" paddingY={{ lg: 0, xs: 3 }}>
            <Container>
              <b2x.Row gap={0}>
                <b2x.Col>
                  <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                    {({ fieldsHelper, formik }) => (
                      <b2x.Div className="search-form-active-filter">
                        {fieldsHelper.activeFilters.length > 0 &&
                          fieldsHelper.activeFilters.map((activeFilter) => (
                            <React.Fragment key={activeFilter.filter.name + activeFilter.filter.id}>
                              {activeFilter.fromSimpleSearch && (
                                <small className="py-1 pe-2">{t('misc.youSearchedFor')}</small>
                              )}
                              <Button
                                className="btn-sm me-3"
                                iconEnd={{ name: 'delete', size: 14 }}
                                label={activeFilter.filter.name}
                                onClick={activeFilter.handleClick}
                                type="button"
                                variant="outline-primary"
                              />
                            </React.Fragment>
                          ))}
                      </b2x.Div>
                    )}
                  </b2x.SearchFormHelper>
                </b2x.Col>
                <b2x.Col className="d-none d-lg-block" size={'auto'}>
                  <Button
                    alignItems="center"
                    className="btn-sm"
                    iconEnd={{ name: 'filter', size: 20 }}
                    label={t('misc.filterAndOrderBy')}
                    onClick={showSearchFiltersOffcanvas}
                    type="button"
                    variant="outline-primary"
                  />
                </b2x.Col>
              </b2x.Row>
            </Container>
          </b2x.Div>
          <Container>
            {searchResult && searchResult.items && (
              <b2x.Div paddingY={{ xl: 4, xs: 3 }}>
                <b2x.Listing name="Search page" products={searchResult.items}>
                  <b2x.ProductsTiles
                    enableExtraInfo
                    gap={{ lg: 3, xs: 2 }}
                    products={searchResult.items.map((product) => ({ product: product }))}
                    productsPerRow={{ lg: 3, md: 3, sm: 2, xl: 3, xs: 2, xxl: 4 }}
                  />
                </b2x.Listing>
              </b2x.Div>
            )}
            {searchResult && (
              <b2x.Div display="flex" justifyContent="center">
                <b2x.Pagination
                  currentPage={searchResult.pageNumber}
                  pageOffset={2}
                  showDots
                  singleStepNavigation
                  totalPages={searchResult.pagesCount}
                />
              </b2x.Div>
            )}
            {page?.content?.body.description && (
              <Section className="my-3 my-lg-5">{b2x.formatHtml(page.content.body.description)}</Section>
            )}
          </Container>
        </Page>
      )}
    </>
  );
};
