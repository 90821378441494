import { AddressApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { useCheckoutContext } from '../CheckoutContext';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { formatAddress } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { CartAddressFromAddressBookFormA } from './CartAddressFromAddressBookFormA';
import { useCustomerAddressFormModal } from './CustomerAddressForm';
import { Radio, RadioProps } from './fields/RadioCheck';
import { FormButtonProps, formikString, getInitialString, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { InvoiceFieldset } from './InvoiceFieldset';
import {
  getInvoiceValidationSchema,
  InvoiceFieldsHelper,
  InvoiceFormValues,
  InvoiceValidationSchema,
  useAddressForm,
} from './useAddressForm';

export interface CartAddressFromAddressBookFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  addresses: Array<AddressApiDto>;
  currentAddress: AddressApiDto | undefined;
  onEditAddressButtonClick?(address: AddressApiDto): void;
  onNewAddressButtonClick?(): void;
  type: 'shipping' | 'billing';
}

interface FormValues {
  addresses: formikString;
  invoice: InvoiceFormValues;
}

type ValidationSchema = {
  addresses: yup.StringSchema;
  invoice: InvoiceValidationSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  addresses: {
    formFields: Array<{
      address: AddressApiDto;
      editAddressButton: FormButtonProps;
      radio: RadioProps;
    }>;
    formGroup: FormGroupProps;
  };
  buttons: {
    cancel?: FormButtonProps;
    newAddress: FormButtonProps;
    submit?: FormButtonProps;
  };
  invoice?: InvoiceFieldsHelper;
}

export const CartAddressFromAddressBookFormHelper = ({
  addresses,
  children,
  className,
  currentAddress,
  initialValues,
  onCancel,
  onEditAddressButtonClick,
  onNewAddressButtonClick,
  onSuccess,
  type,
  ...otherProps
}: CartAddressFromAddressBookFormProps) => {
  // const [addresses, setAddresses] = React.useState<Array<AddressApiDto>>(_addresses);
  const [addressesCount, setAddressesCount] = React.useState<number>(addresses.length);
  const [newlyCreatedAddressId, setNewlyCreatedAddressId] = React.useState<string>();

  // TODO: Meglio usare un useCheckoutContextStrict in modo da poter usare questo componente anche fuori dal checkout
  const { cart, deleteForcedStep, setStepAlreadyDone } = useCheckoutContext();

  const { getInvoiceFieldsHelper, getInvoiceInitialValues } = useAddressForm(
    'CartAddressFromAddressBookForm',
    type,
    currentAddress
  );

  const { shippingCountry } = useAppContext();

  const addressesWithCompatibleCountry = React.useMemo(
    () => addresses.filter((address) => address.country?.code === shippingCountry?.code),
    [addresses, shippingCountry?.code]
  );

  // Se mi arriva un indirizzo in più, quindi appena creato, lo seleziono.
  React.useEffect(() => {
    if (addresses.length > addressesCount) {
      setNewlyCreatedAddressId(addresses[addresses.length - 1].id);
      setAddressesCount(addresses.length);
    }
  }, [addresses, addresses.length, addressesCount]);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      addresses: getInitialString(
        newlyCreatedAddressId
          ? newlyCreatedAddressId
          : currentAddress?.idAddressRif
          ? currentAddress.idAddressRif
          : addressesWithCompatibleCountry.length === 1
          ? addressesWithCompatibleCountry[0].id
          : undefined
      ),
      invoice: getInvoiceInitialValues(),
      ...initialValues,
    }),
    [
      addressesWithCompatibleCountry,
      currentAddress?.idAddressRif,
      getInvoiceInitialValues,
      initialValues,
      newlyCreatedAddressId,
    ]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      addresses: yup.string().required(),
      invoice: getInvoiceValidationSchema(shippingCountry, cart, type === 'billing' ? 'billing' : 'shipping'),
    }),
    [cart, shippingCountry, type]
  );

  const { setBillingAddress, setShippingAddress } = useCartApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const addressToSubmit = addresses.find((address) => address.id === values.addresses);

      // In rubrica non salviamo dati di fatturazione, pulisco in caso ci finissero per sbaglio
      delete addressToSubmit?.company;
      delete addressToSubmit?.taxCode;
      delete addressToSubmit?.vatNumber;
      delete addressToSubmit?.pec;
      delete addressToSubmit?.uniqueCode;

      if (addressToSubmit) {
        switch (type) {
          case 'shipping':
            return setShippingAddress({ address: addressToSubmit, alsoSetTheOtherAddress: false }).then(() => {
              deleteForcedStep();
              setStepAlreadyDone('shippingAddress');
              onSuccess && onSuccess();
            });
          case 'billing':
            return setBillingAddress({
              address: values.invoice.request
                ? {
                    ...addressToSubmit,
                    // Aggiungo eventuali info relative alla fattura.
                    company: values.invoice.type !== 'privatePerson' ? values.invoice.data.company : undefined,
                    pec: values.invoice.type !== 'privatePerson' ? values.invoice.data.pec : undefined,
                    taxCode: values.invoice.data.taxCode,
                    uniqueCode: values.invoice.type !== 'privatePerson' ? values.invoice.data.uniqueCode : undefined,
                    vatNumber: values.invoice.type !== 'privatePerson' ? values.invoice.data.vatNumber : undefined,
                  }
                : addressToSubmit,
              alsoSetTheOtherAddress: false,
            }).then(() => {
              deleteForcedStep();
              setStepAlreadyDone('billingAddress');
              onSuccess && onSuccess();
            });
        }
      } else {
        throw new Error('');
      }
    },
    [addresses, deleteForcedStep, onSuccess, setBillingAddress, setShippingAddress, setStepAlreadyDone, type]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const showCustomerAddressFormModal = useCustomerAddressFormModal();

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  // const { getAddresses } = useCustomerApi();

  return (
    <>
      <HelpedForm<FormValues>
        className={classnames('CartAddressFromAddressBookForm', className)}
        enableReinitialize
        initialValues={_initialValues}
        innerRef={ref}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        {...otherProps}
      >
        {({ formik }) => {
          const fieldsHelper: FieldsHelper = {
            addresses: {
              formFields: addresses.map((address) => ({
                address: address,
                editAddressButton: {
                  label: t('form.cartAddressFromAddressBookForm.addresses.editAddressButton.label'),
                  onClick: () => {
                    onEditAddressButtonClick
                      ? onEditAddressButtonClick(address)
                      : showCustomerAddressFormModal({
                          address: address,
                          duringCheckout: true,
                          onSuccess: () => {
                            if (
                              addressesWithCompatibleCountry.some(
                                (addressWithCompatibleCountry) => addressWithCompatibleCountry.id === address.id
                              )
                            ) {
                              formik.setFieldValue('addresses', address.id);
                            }
                          },
                        });
                  },
                },
                radio: {
                  disabled: !addressesWithCompatibleCountry.includes(address),
                  id: address.id,
                  label: formatAddress(address, { withCountry: true, withName: true }),
                  name: 'addresses',
                  value: address.id,
                },
              })),
              formGroup: { label: 'Radios', names: ['addresses'], omitForAttribute: true },
            },
            buttons: {
              cancel: onCancel
                ? {
                    label: t('form.loginForm.buttons.cancel.label'),
                    onClick: onCancel,
                    type: 'button',
                    variant: appConfig.form?.buttons.cancel?.defaultVariant,
                  }
                : undefined,
              newAddress: {
                label: t('form.cartAddressFromAddressBookForm.buttons.newAddress.label'),
                onClick: () => {
                  onNewAddressButtonClick
                    ? onNewAddressButtonClick()
                    : showCustomerAddressFormModal({
                        duringCheckout: true,
                        // onSuccess: (createdAddressId) => {
                        //   getAddresses().then((response) => {
                        //     setAddresses(response.data);
                        //     wait(100).then(() => {
                        //       formik.setFieldValue('addresses', createdAddressId);
                        //     });
                        //   });
                        // },
                      });
                },
              },
              submit:
                addresses.length > 0
                  ? {
                      disabled: isSubmitButtonDisabled(formik),
                      label: t('form.cartAddressFromAddressBookForm.buttons.submit.label'),
                      type: 'submit',
                      variant: appConfig.form?.buttons.submit?.defaultVariant,
                    }
                  : undefined,
            },
            invoice: type === 'billing' ? getInvoiceFieldsHelper(formik) : undefined,
          };
          return children ? (
            children({ closeModal, fieldsHelper, formik, insideModal })
          ) : (
            <>
              <FormGroup {...fieldsHelper.addresses.formGroup}>
                {fieldsHelper.addresses.formFields.map(({ address, editAddressButton, radio }) => (
                  <p key={address.id}>
                    <Radio {...radio} />
                    <Button {...editAddressButton} />
                  </p>
                ))}
              </FormGroup>
              <p>
                <Button {...fieldsHelper.buttons.newAddress} />
              </p>

              {fieldsHelper.invoice && <InvoiceFieldset invoiceFieldsHelper={fieldsHelper.invoice} />}

              {fieldsHelper.buttons.submit && (
                <p>
                  <Button {...fieldsHelper.buttons.submit} />
                </p>
              )}
            </>
          );
        }}
      </HelpedForm>
    </>
  );
};

export type CartAddressFromAddressBookFormVariants = 'A';

const CartAddressFromAddressBookFormController = (
  props: PropsWithCustomComponentWithoutChildren<CartAddressFromAddressBookFormProps>
) => (
  <VariantsController<CartAddressFromAddressBookFormProps, CartAddressFromAddressBookFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CartAddressFromAddressBookFormA },
      defaultComponent: CartAddressFromAddressBookFormHelper,
      name: 'CartAddressFromAddressBookForm',
    }}
  />
);
export { CartAddressFromAddressBookFormController as CartAddressFromAddressBookForm };
